import { APIRepository } from "~/context/Shared/infrastructure/APIRepository";
import { vectiAppApiUrl } from "~/context/Shared/infrastructure/Config";
import { EmployeeRepository } from "../domain/EmployeeRepository";
import { EmployeeFileDT } from "../domain/EmployeeFileDT";
import { EmployeeFileDTResponse } from "../domain/EmployeeFileDTResponse";

export function createApiEmployeeRepository(): EmployeeRepository {
  return new APIEmployeeRepository();
}

export class APIEmployeeRepository
  extends APIRepository
  implements EmployeeRepository {
  constructor() {
    super(vectiAppApiUrl());
  }

  async addEmployeesFromDT(employeesFileDT: EmployeeFileDT): Promise<EmployeeFileDTResponse> {
    console.log("addEmployeesFromDT companyId: " + employeesFileDT.company_uid.toString());
    try {
      const resp = await this.doPost<EmployeeFileDT, EmployeeFileDTResponse>("/api/employees/upload/DT", null, employeesFileDT);
      return resp;
    } catch (error) {
      console.log("error addEmployeesFromDT:" + error);
      return { ok: false, message: "Error: " + error, company_uid: employeesFileDT.company_uid, records: 0, created: new Date() } as EmployeeFileDTResponse;
    }
  }
}
