export const routes = [
  {
    path: '/',
    name: '/',
    component: () => import('/builds/vecti/payments/packages/apps/Payments/frontend/src/pages/index.vue'),
    /* no children */
  },
  {
    path: '/access',
    /* internal name: '/access' */
    /* no component */
    children: [
      {
        path: '',
        name: '/access/',
        component: () => import('/builds/vecti/payments/packages/apps/Payments/frontend/src/pages/access/index.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/accounts',
    /* internal name: '/accounts' */
    /* no component */
    children: [
      {
        path: '',
        name: '/accounts/',
        component: () => import('/builds/vecti/payments/packages/apps/Payments/frontend/src/pages/accounts/index.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/autoreconciliations',
    /* internal name: '/autoreconciliations' */
    /* no component */
    children: [
      {
        path: '',
        name: '/autoreconciliations/',
        component: () => import('/builds/vecti/payments/packages/apps/Payments/frontend/src/pages/autoreconciliations/index.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/businesspartners',
    /* internal name: '/businesspartners' */
    /* no component */
    children: [
      {
        path: 'client',
        name: '/businesspartners/client',
        component: () => import('/builds/vecti/payments/packages/apps/Payments/frontend/src/pages/businesspartners/client.vue'),
        /* no children */
      },
      {
        path: 'clients',
        name: '/businesspartners/clients',
        component: () => import('/builds/vecti/payments/packages/apps/Payments/frontend/src/pages/businesspartners/clients.vue'),
        /* no children */
      },
      {
        path: 'collaborators',
        name: '/businesspartners/collaborators',
        component: () => import('/builds/vecti/payments/packages/apps/Payments/frontend/src/pages/businesspartners/collaborators.vue'),
        /* no children */
      },
      {
        path: 'employees',
        name: '/businesspartners/employees',
        component: () => import('/builds/vecti/payments/packages/apps/Payments/frontend/src/pages/businesspartners/employees.vue'),
        /* no children */
      },
      {
        path: 'provider',
        name: '/businesspartners/provider',
        component: () => import('/builds/vecti/payments/packages/apps/Payments/frontend/src/pages/businesspartners/provider.vue'),
        /* no children */
      },
      {
        path: 'providers',
        name: '/businesspartners/providers',
        component: () => import('/builds/vecti/payments/packages/apps/Payments/frontend/src/pages/businesspartners/providers.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/company',
    /* internal name: '/company' */
    /* no component */
    children: [
      {
        path: '',
        name: '/company/',
        component: () => import('/builds/vecti/payments/packages/apps/Payments/frontend/src/pages/company/index.vue'),
        /* no children */
      },
      {
        path: 'relation',
        name: '/company/relation',
        component: () => import('/builds/vecti/payments/packages/apps/Payments/frontend/src/pages/company/relation.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/documents',
    /* internal name: '/documents' */
    /* no component */
    children: [
      {
        path: '',
        name: '/documents/',
        component: () => import('/builds/vecti/payments/packages/apps/Payments/frontend/src/pages/documents/index.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/employees',
    /* internal name: '/employees' */
    /* no component */
    children: [
      {
        path: 'upload-employees',
        name: '/employees/upload-employees',
        component: () => import('/builds/vecti/payments/packages/apps/Payments/frontend/src/pages/employees/upload-employees.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/expenses',
    /* internal name: '/expenses' */
    /* no component */
    children: [
      {
        path: 'createExpense',
        name: '/expenses/createExpense',
        component: () => import('/builds/vecti/payments/packages/apps/Payments/frontend/src/pages/expenses/createExpense.vue'),
        /* no children */
      },
      {
        path: 'createWage',
        name: '/expenses/createWage',
        component: () => import('/builds/vecti/payments/packages/apps/Payments/frontend/src/pages/expenses/createWage.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/gastos',
    /* internal name: '/gastos' */
    /* no component */
    children: [
      {
        path: '',
        name: '/gastos/',
        component: () => import('/builds/vecti/payments/packages/apps/Payments/frontend/src/pages/gastos/index.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/incomes',
    /* internal name: '/incomes' */
    /* no component */
    children: [
      {
        path: '',
        name: '/incomes/',
        component: () => import('/builds/vecti/payments/packages/apps/Payments/frontend/src/pages/incomes/index.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/invoices',
    /* internal name: '/invoices' */
    /* no component */
    children: [
      {
        path: '',
        name: '/invoices/',
        component: () => import('/builds/vecti/payments/packages/apps/Payments/frontend/src/pages/invoices/index.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/movements',
    /* internal name: '/movements' */
    /* no component */
    children: [
      {
        path: '',
        name: '/movements/',
        component: () => import('/builds/vecti/payments/packages/apps/Payments/frontend/src/pages/movements/index.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/movements-all',
    /* internal name: '/movements-all' */
    /* no component */
    children: [
      {
        path: '',
        name: '/movements-all/',
        component: () => import('/builds/vecti/payments/packages/apps/Payments/frontend/src/pages/movements-all/index.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/movementsAll',
    /* internal name: '/movementsAll' */
    /* no component */
    children: [
      {
        path: '',
        name: '/movementsAll/',
        component: () => import('/builds/vecti/payments/packages/apps/Payments/frontend/src/pages/movementsAll/index.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/outcomes',
    /* internal name: '/outcomes' */
    /* no component */
    children: [
      {
        path: '',
        name: '/outcomes/',
        component: () => import('/builds/vecti/payments/packages/apps/Payments/frontend/src/pages/outcomes/index.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/outinreconciliations',
    /* internal name: '/outinreconciliations' */
    /* no component */
    children: [
      {
        path: '',
        name: '/outinreconciliations/',
        component: () => import('/builds/vecti/payments/packages/apps/Payments/frontend/src/pages/outinreconciliations/index.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/reconciliations',
    /* internal name: '/reconciliations' */
    /* no component */
    children: [
      {
        path: '',
        name: '/reconciliations/',
        component: () => import('/builds/vecti/payments/packages/apps/Payments/frontend/src/pages/reconciliations/index.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/start',
    /* internal name: '/start' */
    /* no component */
    children: [
      {
        path: '',
        name: '/start/',
        component: () => import('/builds/vecti/payments/packages/apps/Payments/frontend/src/pages/start/index.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/synchronizations',
    /* internal name: '/synchronizations' */
    /* no component */
    children: [
      {
        path: 'autoreconciliations',
        name: '/synchronizations/autoreconciliations',
        component: () => import('/builds/vecti/payments/packages/apps/Payments/frontend/src/pages/synchronizations/autoreconciliations.vue'),
        /* no children */
      },
      {
        path: 'movements',
        name: '/synchronizations/movements',
        component: () => import('/builds/vecti/payments/packages/apps/Payments/frontend/src/pages/synchronizations/movements.vue'),
        /* no children */
      },
      {
        path: 'sii',
        name: '/synchronizations/sii',
        component: () => import('/builds/vecti/payments/packages/apps/Payments/frontend/src/pages/synchronizations/sii.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/test',
    /* internal name: '/test' */
    /* no component */
    children: [
      {
        path: '',
        name: '/test/',
        component: () => import('/builds/vecti/payments/packages/apps/Payments/frontend/src/pages/test/index.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/welcome',
    /* internal name: '/welcome' */
    /* no component */
    children: [
      {
        path: '',
        name: '/welcome/',
        component: () => import('/builds/vecti/payments/packages/apps/Payments/frontend/src/pages/welcome/index.vue'),
        /* no children */
      },
      {
        path: 'account-info',
        name: '/welcome/account-info',
        component: () => import('/builds/vecti/payments/packages/apps/Payments/frontend/src/pages/welcome/account-info.vue'),
        /* no children */
      },
      {
        path: 'aditional-info',
        name: '/welcome/aditional-info',
        component: () => import('/builds/vecti/payments/packages/apps/Payments/frontend/src/pages/welcome/aditional-info.vue'),
        /* no children */
      },
      {
        path: 'company-info',
        name: '/welcome/company-info',
        component: () => import('/builds/vecti/payments/packages/apps/Payments/frontend/src/pages/welcome/company-info.vue'),
        /* no children */
      },
      {
        path: 'config-sync',
        name: '/welcome/config-sync',
        component: () => import('/builds/vecti/payments/packages/apps/Payments/frontend/src/pages/welcome/config-sync.vue'),
        /* no children */
      },
      {
        path: 'fail-process',
        name: '/welcome/fail-process',
        component: () => import('/builds/vecti/payments/packages/apps/Payments/frontend/src/pages/welcome/fail-process.vue'),
        /* no children */
      },
      {
        path: 'finish',
        name: '/welcome/finish',
        component: () => import('/builds/vecti/payments/packages/apps/Payments/frontend/src/pages/welcome/finish.vue'),
        /* no children */
      },
      {
        path: 'sii-info',
        name: '/welcome/sii-info',
        component: () => import('/builds/vecti/payments/packages/apps/Payments/frontend/src/pages/welcome/sii-info.vue'),
        /* no children */
      },
      {
        path: 'validate-company',
        name: '/welcome/validate-company',
        component: () => import('/builds/vecti/payments/packages/apps/Payments/frontend/src/pages/welcome/validate-company.vue'),
        /* no children */
      },
      {
        path: 'validate-email',
        name: '/welcome/validate-email',
        component: () => import('/builds/vecti/payments/packages/apps/Payments/frontend/src/pages/welcome/validate-email.vue'),
        /* no children */
      }
    ],
  }
]
