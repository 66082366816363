import { createStore } from "vuex";
import { Company } from "~/context/Company/domain/Company";

const store = createStore({
  state: {
    inUseCompany: null,
    inUseCompanyHasAccount: null,
    inUseCompanyHasEmployees: null,
    userExtended: null,
    userCompanies: new Map<string, any>(),
    expandMenu: false,
    dialog: false,
    updateSII: false,
    updateBank: false,
    updateReconciliation: false,
    message: '',
    reloadMenu: false,
    menuSelection: {
      movementsAll: false,
      movements: false,
      company: false,
      invoices: false,
      documents: false,
      outcomes: false,
      incomes: false,
      reconciliations: false,
      clients: false,
      providers: false,
      collaborators: false,
      employees: false,
    },
    dataSetup: {
      taxAgency: false,
      account: false,
    },
    employeeParams: {
      date: null,
    },
    welcomeParams: {
      firstName: '',
      lastName: '',
      gen: '',
      email: '',
      legalId: '',
      companyName: '',
      companyEmail: '',
    },
    notification: {
      show: false,
      message: '',
      duration: 1000,
    }
  },
  getters: {
    getInUseCompany(state: any) {
      return state.inUseCompany;
    },
    getInUseCompanyData(state: any) {
      const myCompanies = state.userExtended.companies;
      console.log("myCompanies:" + JSON.stringify(myCompanies));
      return myCompanies.find((company: Company) => company.uid === state.inUseCompany);
    },
    getUserExtended(state: any) {
      return state.userExtended;
    },
    getUserCompanies(state: any) {
      return state.userCompanies;
    },
    getExpandMenu(state: any) {
      return state.expandMenu;
    },
    getMenuSelection(state: any) {
      return state.menuSelection;
    },
    getReloadMenu(state: any) {
      return state.reloadMenu;
    },
    getInUseCompanyHasAccount(state: any) {
      return state.inUseCompanyHasAccount;
    },
    getInUseCompanyHasEmployees(state: any) {
      return state.inUseCompanyHasEmployees;
    },
    getWelcomeParams(state: any) {
      return state.welcomeParams;
    },
    getEmployeeParams(state: any) {
      return state.employeeParams;
    },
    getDataSetup(state: any) {
      return state.dataSetup;
    },
    getUpdateSII(state: any) {
      return state.updateSII;
    },
    getUpdateBank(state: any) {
      return state.updateBank;
    },
    getUpdateReconciliation(state: any) {
      return state.updateReconciliation;
    },
  },
  mutations: {
    setMenuSelection(state: any, value: any) {
      state.menuSelection = value;
    },
    setReloadMenu(state: any, value: any) {
      state.reloadMenu = value;
    },
    setExpandMenu(state: any, value: any) {
      state.expandMenu = value;
    },
    setUserExtended(state: any, value: any) {
      state.userExtended = value;
    },
    setUserCompanies(state: any, value: any) {
      state.userCompanies = value;
    },
    setInUseCompany(state: any, value: any) {
      //  console.log("value COMPANY:" + JSON.stringify(value));
      state.inUseCompany = value;
    },
    setInUseCompanyHasAccount(state: any, value: any) {
      state.inUseCompanyHasAccount = value;
    },
    setInUseCompanyHasEmployees(state: any, value: any) {
      state.inUseCompanyHasEmployees = value;
    },
    setWelcomeParams(state: any, value: any) {
      state.welcomeParams = value;
    },
    setEmployeeParams(state: any, value: any) {
      state.employeeParams = value;
    },
    setDataSetup(state: any, value: any) {
      state.dataSetup = value;
    },
    setUpdateSII(state: any, value: any) {
      state.updateSII = value;
    },
    setUpdateBank(state: any, value: any) {
      state.updateBank = value;
    },
    setUpdateReconciliation(state: any, value: any) {
      state.updateReconciliation = value;
    },
    addUserCompanyAccount(state: any, value: any) {
      //console.log("value COMPANY:" + JSON.stringify(value));
      if (
        value.length > 0 &&
        !state.userCompanies.has(value[0].company_uid.toString())
      ) {
        state.userCompanies.set(value[0].company_uid.toString(), value);
        state.inUseCompanyHasAccount = true;
      }
    },
  },
  actions: {
    resetMenuSelection(context) {
      context.commit("setMenuSelection", {
        movementsAll: false,
        movements: false,
        company: false,
        invoices: false,
        documents: false,
        outcomes: false,
        incomes: false,
        reconciliations: false,
        clients: false,
        providers: false,
        collaborators: false,
        employees: false,
      });
    },
    closeDialog(context) {
      context.state.dialog = false;
      context.state.message = '';
    },
    openDialog(context, message) {
      context.state.dialog = true;
      context.state.message = message;
    },
    openNotification(context, options) {
      context.state.notification.show = true;
      context.state.notification.message = options.message;
      context.state.notification.duration = options.duration;
      setTimeout(() => {
        context.state.notification.show = false;
        context.state.notification.message = '';
      }, options.duration);
    },
    openError(context, options) {
      context.state.notification.show = true;
      context.state.notification.message = options.message;
      context.state.notification.duration = options.duration;
      setTimeout(() => {
        context.state.notification.show = false;
        context.state.notification.message = '';
      }, options.duration);
    },
  },
  modules: {},
});

export default store;
